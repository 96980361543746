import React, { useEffect } from 'react'
import { useTonConnectModal, useTonAddress } from '@tonconnect/ui-react';
import style from "./TonConnectBtn.module.css"

export const TonConnectBtn = ({text, setWalletCon}) => {
    
    const { state, open } = useTonConnectModal();
    const userFriendlyAddress = useTonAddress();
    
    const clickHanlder = () => {        
        open()            
       setWalletCon(true)             
        
    }

  return (
    <button className={style.ton_vpn} onClick={clickHanlder}>{text}</button>
  )
}
