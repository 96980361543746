import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Welcomecard } from "../components/OnboardingCards/Welcomecard";
import { OnboardingCard } from "../components/OnboardingCards/OnboardingCard";
import { ConnectWallet } from "../components/OnboardingCards/ConnectWallet";

const Onboardingpage = ({ setOnboarding }) => {
  const hapticFeedBack = () => {
    window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
  };

  const onboarding_handlder = () => {
    window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
    localStorage.setItem("onboarding", true);
    setOnboarding(true);
  };

  const [cardindex, setIndex] = useState(0);

  return (
    <div className="container">
      <div className="onboarding">
        <div className="onboarding__main_content">
          {cardindex === 0 ? (
            <Welcomecard />
          ) : cardindex === 1 ? (
            <OnboardingCard
              img_scr="/images/onboarding_icons/earnpaoints.png"
              h1_text="Earn points"
              p_text="And collect your reward every day"
            />
          ) : cardindex === 2 ? (
            <OnboardingCard
              img_scr="/images/onboarding_icons/usevpn.png"
              h1_text="Buy and use VPN"
              p_text="Protect yourself in the network and get a mining bonus"
            />
          ) : cardindex === 3 ? (
            <OnboardingCard
              img_scr="/images/onboarding_icons/getrewards.png"
              h1_text="Get rewards"
              p_text="For invited friends and completed tasks"
            />
          ) : cardindex === 4 ? (
            <OnboardingCard
              img_scr="inviteyourfriends.png"
              h1_text="Invite your friends"
              p_text="And get percent of their income"
            />
          ) : cardindex === 5 ? 
            (<OnboardingCard
              img_scr="/images/onboarding_icons/paywithcrypto.png"
              h1_text="Pay with crypto"
              p_text="From any convenient wallet"
            />) : (
              cardindex === 6 ? <ConnectWallet setOnboarding={setOnboarding}/> : null
            )
          }
        </div>
        <div className="onboarding_footer">
        {cardindex <= 5 ? (
            <button
              onClick={() => {
                setIndex(cardindex + 1);
                hapticFeedBack();
              }}
              className="onboarding__btn"
            >
              <p className="onboarding__btn__text">
                {cardindex === 0 ? "Start" : "Next"}
              </p>
              <p className="onboarding__btn__counter">
                {cardindex === 0 ? "" : `${cardindex}/5`}
              </p>
            </button>
          ) : null
        }
      </div>
      </div>     
    </div>
  );
};

export default Onboardingpage;
