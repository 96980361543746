import React from 'react'
import Lottie from "lottie-react";
import developmentAnimation from "../components/Lottie/development.json";

const Friendspage = () => {
    return (

    <div className="taskspage">
        <div className="taskspage__main">
            <svg xmlns="http://www.w3.org/2000/svg" width="121" height="80" viewBox="0 0 121 80" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M48.2196 22.986C48.2196 32.5747 40.9859 39.9766 32.799 39.9766C24.6681 39.9766 17.3785 32.5747 17.3785 23.0981C17.3785 13.5654 24.6681 6.5 32.799 6.5C40.9859 6.5 48.2196 13.3971 48.2196 22.986ZM41.159 79.9017H8.68687C2.68689 79.9017 0.5 77.5466 0.5 73.5653C0.5 61.4532 12.9486 46.7056 32.799 46.7056C40.0315 46.7056 46.2796 48.6523 51.3038 51.7122C43.9216 58.1861 39.7528 66.4514 39.7528 73.9019C39.7528 75.9955 40.1618 78.065 41.159 79.9017Z"
                      fill="white"/>
                <path
                    d="M83.3779 38.9672C92.7984 38.9672 101.097 30.4999 101.097 19.4532C101.097 8.46261 92.7984 0.5 83.3779 0.5C74.0134 0.5 65.7143 8.6869 65.7143 19.5653C65.7143 30.4999 74.0134 38.9672 83.3779 38.9672ZM56.2938 79.9017H110.518C118.032 79.9017 120.5 77.7709 120.5 73.9017C120.5 62.5186 106.256 46.8176 83.3779 46.8176C60.5554 46.8176 46.3125 62.5186 46.3125 73.9017C46.3125 77.7709 48.7797 79.9017 56.2938 79.9017Z"
                    fill="url(#paint0_linear_520_2091)"/>
                <defs>
                    <linearGradient id="paint0_linear_520_2091" x1="95.7246" y1="95.5327" x2="74.5148" y2="-15.5472"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F44E17"/>
                        <stop offset="1" stopColor="#FFAC00"/>
                    </linearGradient>
                </defs>
            </svg>
            <div className="taskspage__main__text">
                <h1>Invite Friends</h1>
                <p>Coming soon</p>
            </div>
        </div>

        <div className="taskpage__links">
            <a href="https://x.com/DeGuardVPN"  rel="noreferrer" target='_blank'>Join Twitter</a>
            <a href="https://t.me/DeGuardVPN"  rel="noreferrer" target='_blank'>Join Telegram</a>
        </div>
    </div>
)


}


export {Friendspage}

