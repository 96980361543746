export const PlanData = (partnerId) => {
    const plan_data_yescoin = {
        partner_name: 'Yumify',
        partner_logo: '/images/partners_logos/yumify.jpg',
        1: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
            "sale_amount": 15,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 6375,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
            "sale_amount": 15,
            "plan_name": "Annual plan",
            "plan_price_usd": 2125,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
            "sale_amount": 15,
            "plan_name": "Monthly plan",
            "plan_price_usd": 297,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }
    const plan_data_spheroid_universe = {
        partner_name: 'Spheroid Universe',
        partner_logo: '/images/partners_logos/spheroid_universe.jpg',
        1: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
            "sale_amount": 15,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 6375,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
            "sale_amount": 15,
            "plan_name": "Annual plan",
            "plan_price_usd": 2125,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
            "sale_amount": 15,
            "plan_name": "Monthly plan",
            "plan_price_usd": 297,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }
    const plan_data_steamify = {
        partner_name: 'Steamify',
        partner_logo: '/images/partners_logos/steamify.jpg',
        1: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
            "sale_amount": 15,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 6375,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
            "sale_amount": 15,
            "plan_name": "Annual plan",
            "plan_price_usd": 2125,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
            "sale_amount": 15,
            "plan_name": "Monthly plan",
            "plan_price_usd": 297,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }
    const plan_data_dragon = {
        partner_name: "Dragon",
        partner_logo: '/images/partners_logos/dragon.jpg',
        1: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
            "sale_amount": 15,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 6375,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
            "sale_amount": 15,
            "plan_name": "Annual plan",
            "plan_price_usd": 2125,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
            "sale_amount": 15,
            "plan_name": "Monthly plan",
            "plan_price_usd": 297,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }
    const plan_data_secret_pad = {
        partner_name: "Secret Pad",
        partner_logo: '/images/partners_logos/secret_pad.jpg',
        1: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
            "sale_amount": 15,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 6375,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
            "sale_amount": 15,
            "plan_name": "Annual plan",
            "plan_price_usd": 2125,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
            "sale_amount": 15,
            "plan_name": "Monthly plan",
            "plan_price_usd": 297,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }
    const plan_data_mole = {
        partner_name: "Mole",
        partner_logo: '/images/partners_logos/mole.jpg',
        1: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
            "sale_amount": 15,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 6375,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
            "sale_amount": 15,
            "plan_name": "Annual plan",
            "plan_price_usd": 2125,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
            "sale_amount": 15,
            "plan_name": "Monthly plan",
            "plan_price_usd": 297,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }

    const plan_mapping = {
        2: plan_data_yescoin,
        4: plan_data_spheroid_universe,
        5: plan_data_steamify,
        6: plan_data_secret_pad,
        7: plan_data_dragon,
        8: plan_data_mole
    }

    return plan_mapping[partnerId]
}