const DeGuardIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={110}
    height={141}
    viewBox="0 0 110 141"
    fill="none"
    style={{position: "relative", zIndex: '3'}}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1422 10.3932C30.1466 13.0861 23.9453 15.2922 17.5983 16.9894L15.4972 17.5226V34.4833C19.8925 32.9557 24.2294 31.2724 28.499 29.4366C37.5833 25.6533 46.3564 21.212 54.7409 16.1514C63.1467 21.2297 71.9428 25.6848 81.0541 29.4784C85.2827 31.2883 89.5768 32.9487 93.9279 34.4565V17.5226L91.8272 16.9894C85.471 15.3025 79.2609 13.1061 73.2553 10.4212C67.3584 7.85731 61.6642 4.84518 56.2254 1.41119L54.7125 0.428711L53.1718 1.38281C47.733 4.81717 42.0399 7.8293 36.1422 10.3932Z"
      fill="white"
    />
    <path
      d="M28.4956 29.4334C19.2807 33.3957 9.75204 36.6471 -7.62939e-06 39.1569V69.6019C-7.62939e-06 95.1363 13.3667 112.761 27.1237 124.176C34.0079 129.886 40.9272 133.981 46.1597 136.645C48.7715 137.975 50.9466 138.939 52.4628 139.563C53.1633 139.851 53.7141 140.063 54.0958 140.203H54.1195L54.7887 140.429C56.4075 139.831 69.4662 134.83 82.4481 124.081C96.2123 112.685 109.585 95.1112 109.585 69.6019V39.1579C99.8209 36.6629 90.2796 33.4255 81.0507 29.4752C71.9394 25.6817 63.1433 21.2265 54.7375 16.1482C46.353 21.2088 37.5799 25.6501 28.4956 29.4334Z"
      fill="url(#paint0_linear_17_442)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17_442"
        x1={67.4237}
        y1={140.383}
        x2={22.0235}
        y2={16.1856}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F44E17" />
        <stop offset={1} stopColor="#FFAC00" />
      </linearGradient>
    </defs>
  </svg>
);
export default DeGuardIcon;

