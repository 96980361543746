import * as React from "react";
const ConnectWalletSvg = (props) => (
  <svg
    width={133}
    height={118}
    viewBox="0 0 133 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M120.526 17.3528H20.786H0.00683594C0.00683594 8.18402 7.46136 0.729492 16.6302 0.729492H112.214C119.097 0.729492 124.682 6.3139 124.682 13.197C124.682 15.4827 122.812 17.3528 120.526 17.3528Z"
      fill="white"
    />
    <path
      d="M0.00683594 25.8418H119.799C127.071 25.8418 132.994 31.4262 132.994 38.3093V104.803C132.994 111.686 127.071 117.27 119.799 117.27H16.6302C7.46136 117.27 0.00683594 109.842 0.00683594 100.647V25.8418Z"
      fill="url(#paint0_linear_179_12303)"
    />
    <path
      d="M108.81 79.8107C104.212 79.8107 100.498 76.0908 100.498 71.4864C100.498 66.882 104.212 63.1621 108.81 63.1621C113.407 63.1621 117.121 66.882 117.121 71.4864C117.121 76.0908 113.407 79.8107 108.81 79.8107Z"
      fill="#0A0C0D"
    />
    <defs>
      <linearGradient
        id="paint0_linear_179_12303"
        x1={88.5824}
        y1={135.269}
        x2={72.5637}
        y2={4.66543}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F44E17" />
        <stop offset={1} stopColor="#FFAC00" />
      </linearGradient>
    </defs>
  </svg>
);
export default ConnectWalletSvg;
