import React, {useEffect} from "react";
import style from "./VpnCard.module.css";
import VpnCardCercle from "../../Icons/svg/VpnCardCercle";
import {FirstBlockCard} from "./HeaderCard/FirstBlockCard";
export const VpnCard = (props) => {
    console.log(props);
    return (
        <div className={style.vpn_card__main}>
            <FirstBlockCard props={props.props} nomis={props.nomis} notCoin={props.notCoin} />
            {props.nomis &&
                <div className={style.nomis_offer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                        <path
                            d="M28 14.0929C28 6.30962 21.732 0 14 0C6.26801 0 0 6.30962 0 14.0929C0 21.8762 6.26801 28.1859 14 28.1859C21.732 28.1859 28 21.8762 28 14.0929Z"
                            fill="url(#paint0_radial_514_10492)"/>
                        <path
                            d="M21.2091 8.96344C21.1625 8.47019 20.9058 8.01217 20.5091 7.70686C20.1125 7.41322 19.5991 7.2958 19.1091 7.40149C18.8875 7.44845 18.6541 7.56593 18.1991 7.78905C17.8258 7.97694 17.6391 8.07091 17.4525 8.1531C16.6825 8.45846 15.8425 8.5172 15.0375 8.32926C14.8391 8.2823 14.6408 8.21184 14.2441 8.08263L13.6025 7.87124C12.1208 7.37799 11.3858 7.13136 10.6508 7.26056C9.9508 7.38977 9.3208 7.76554 8.88915 8.32926C8.4225 8.91648 8.29415 9.70332 8.0375 11.2418L6.8825 18.253C6.8008 18.7698 6.75415 19.0047 6.7775 19.2278C6.82415 19.7211 7.0808 20.1791 7.4775 20.4844C7.87415 20.778 8.3875 20.8955 8.8658 20.7898C9.0875 20.7428 9.3208 20.6253 9.79915 20.3787C10.1608 20.2025 10.3358 20.1086 10.5225 20.0382C11.2925 19.7328 12.1325 19.6741 12.9375 19.862C13.1358 19.909 13.3341 19.9794 13.7308 20.1086L14.3725 20.32C15.5858 20.7193 16.2975 20.9659 16.9275 20.9659C17.0675 20.9659 17.1958 20.9659 17.3358 20.9307C18.0358 20.8015 18.6658 20.4257 19.0975 19.862C19.5641 19.2748 19.6925 18.4997 19.9491 16.9494L21.1041 9.94994C21.1858 9.43324 21.2325 9.18661 21.2091 8.96344Z"
                            fill="white"/>
                        <defs>
                            <radialGradient id="paint0_radial_514_10492" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(14) rotate(90) scale(28.1859 28)">
                                <stop stopColor="#99EEFF"/>
                                <stop offset="0.526042" stopColor="#BB33FF"/>
                                <stop offset="0.864583" stopColor="#2200CC"/>
                                <stop offset="1" stopColor="#00AACC"/>
                            </radialGradient>
                        </defs>
                    </svg>
                    <p>Special offer for Nomis Score holders</p>
                </div>
            }
            {props.notCoin &&
                <div className={style.nomis_offer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <g clipPath="url(#clip0_673_47292)">
                            <path
                                d="M14 0C21.732 0 28 6.268 28 14C28 21.732 21.732 28 14 28C6.268 28 0 21.732 0 14C0 6.268 6.268 0 14 0Z"
                                fill="#FFAC00"/>
                            <path
                                d="M18.7806 20.1875H9.21972C7.46182 20.1875 6.34762 18.2913 7.23202 16.7583L13.1326 6.5309C13.5177 5.8631 14.4826 5.8631 14.8677 6.5309L20.7695 16.7583C21.6527 18.2888 20.5385 20.1875 18.7818 20.1875H18.7806ZM13.1278 9.59795L11.8428 12.085L8.74207 17.6307C8.53752 17.9856 8.79017 18.4405 9.21852 18.4405H13.1266V9.59675L13.1278 9.59795ZM19.2558 17.6318L16.1563 12.0838L14.8713 9.59795V18.4416H18.7794C19.2077 18.4416 19.4604 17.9868 19.2558 17.6318Z"
                                fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_673_47292">
                                <rect width="28" height="28" fill="white" transform="matrix(1 0 0 -1 0 28)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p>Special offer for {props.props.tir_type} Notcoin Users</p>
                </div>
            }
            {props.partner_logo && props.partner_name &&
                <div className={style.nomis_offer}>
                    <img src={props.partner_logo} alt={props.partner_name} width="28" height="28" style={{borderRadius: "50%"}} />
                    <p>Special offer for {props.partner_name} Users</p>
                </div>
            }
            <div className={style.vpn_card__main__second_block}>
                {props.props.plan_features.map((el) => {
                    return (
                        <div className={style.vpn_card__main__second_block__item + " " + "second_block_item"}>
                            <img src={el[0]} alt="1"/>
                            <div className={style.vpn_card__main__second_block__item__text}>
                                <p>{el[1]}</p>
                                <p>{el[2]}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
