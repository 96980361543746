import React, { useEffect, useState } from "react";
import ConnectWalletSvg from "../Icons/svg/ConnectWalletSvg";
import style from "./ConnectWallet.module.css";
import { useTonConnectModal } from "@tonconnect/ui-react";
import { useTonWallet } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { TonConnectBtn } from "../Buttons/TonConnectBtn/TonConnectBtn";

import { Link } from "react-router-dom";

export const ConnectWallet = ( {setOnboarding} ) => {
  const { state, open } = useTonConnectModal();
  
    const wallet = useTonAddress();
  
  const clickHanlder = () => {
    localStorage.setItem("onboarding", true)    
    setOnboarding(true)
  }
  
  
  useEffect(() => {
    if (state.status === "opened") {   
        const onboarding = document.getElementsByClassName("onboarding")                   
    }
    if (wallet) {
        setOnboarding(true)
    }
  })
  
  return (
    <div className={style.conect_wallet}>
      <ConnectWalletSvg style={{marginBottom: "54px"}}/>
      <div className={style.conect_wallet__main}>
        <h1>Connect wallet</h1>
        <p>
          It will be useful for receiving <br /> tokens and paying for VPN
        </p>
      </div>
      <div className={style.connect_wallet_btns}>
        <TonConnectBtn text="Connect wallet"/>
        <Link to='/' onClick={clickHanlder}>Not Now</Link>
      </div>
    </div>
  );
};
