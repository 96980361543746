import {VpnCard} from "../VpnPlanCards/PassCard/VpnCard";
import styles from "../VpnActive/VpnActive.module.css";
import React, {useState} from "react";

export const Partners = ({index, isBuyBtnActive, changeIndexHandler, buttonHandler, plan_data, partnerId}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const TG_WEB_APP = window.Telegram.WebApp



    const paymentHandler = () => {
        buttonHandler(true)
        const getInvoice = () => fetch(`${apiUrl}/miniapp/create_invoice`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }, body: JSON.stringify({
                plan_id: plan_data[index].plan_id,
                user_id: localStorage.getItem('user_id'),
                lang_code: TG_WEB_APP.initDataUnsafe.user?.language_code || 'ru',
                from_partner: partnerId
            })
        }).then(res => res.json().then((res) => {
            if (res?.invoice_link) {
                localStorage.setItem("tmp_plan_id", plan_data[index].plan_id)
                TG_WEB_APP.openInvoice(res.invoice_link);
                // setInvoiceLink(res.invoice_link);
                // setBuyBtnActive(false)
            }
        })).catch(res =>  buttonHandler(false));

        getInvoice();
    }

    console.log(plan_data.partner_logo, plan_data.partner_name)
    return (
        <div className="vpn__heading">
            <div className="vpn__heading__main">
                <div className="vpn__button_slider">
                    <button
                        className={index === 1 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                        onClick={() => {
                            changeIndexHandler(1)
                        }}>Forever
                    </button>
                    <button
                        className={index === 2 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                        onClick={() => {
                            changeIndexHandler(2)
                        }}>Annual
                    </button>
                    <button
                        className={index === 3 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                        onClick={() => {
                            changeIndexHandler(3)
                        }}>Monthly
                    </button>
                </div>
                <VpnCard
                    props={plan_data[index]}
                    nomis={false}
                    notCoin={false}
                    partner_logo={plan_data.partner_logo}
                    partner_name={plan_data.partner_name}
                />
            </div>
            <button disabled={isBuyBtnActive} className="vpn__button_buy_plan_btn" onClick={() => {
                paymentHandler()
                // hapticFeedBack()
            }}>
                {isBuyBtnActive ? (<div className={styles.loader}>
                    <div className={styles.inner + " " + styles.one}></div>
                    <div className={styles.inner + " " + styles.two}></div>
                    <div className={styles.inner + " " + styles.three}></div>
                </div>) : (<>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.82867 19.7716C4.24899 20.0897 4.76021 19.9647 5.37366 19.5217L9.99729 16.1249L14.6323 19.5217C15.2344 19.9647 15.7456 20.0897 16.1659 19.7716C16.5749 19.4649 16.6544 18.9423 16.4045 18.238L14.5755 12.7964L19.2446 9.4451C19.858 9.02476 20.1193 8.559 19.9489 8.05913C19.7899 7.582 19.3127 7.34345 18.563 7.3548L12.8374 7.40024L11.0993 1.92457C10.872 1.20889 10.5199 0.833984 9.99729 0.833984C9.48609 0.833984 9.13392 1.20889 8.89535 1.92457L7.15723 7.40024L1.43163 7.3548C0.681867 7.34345 0.216091 7.582 0.0456871 8.05913C-0.113356 8.559 0.147931 9.02476 0.750036 9.4451L5.4191 12.7964L3.5901 18.238C3.34018 18.9423 3.4197 19.4649 3.82867 19.7716Z"
                            fill="#0A0C0D"/>
                    </svg>
                    <p style={{
                        color: "black",
                        fontSize: "16px",
                        fontFamily: "VK Sans Display",
                        fontWeight: "500",
                        lineHeight: "140%"
                    }}>Buy with Stars</p>
                </>)}
            </button>

        </div>
    )
}
