import React, {useEffect, useState} from 'react'
import {Userpointbalance} from '../components/UserPointBalance/Userpointbalance'
import {TonConnectButton, useIsConnectionRestored, useTonAddress} from '@tonconnect/ui-react'
import {NotProteced} from '../components/HomeProtect/NotProteced'
import {Footer} from '../components/Footer/Footer'
import {InitialCard} from '../components/MiningCards/InitialCard'
import {Protected} from "../components/HomeProtect/Protected";
import {PushWalletConnect} from "../components/Notification/PushWalletConnect";

const Homepage = (props) => {
    let userName = window.Telegram.WebApp.initDataUnsafe?.user?.username;
    let userID = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    const userFriendlyAddress = useTonAddress();

    return (
        <div className='home_page'>
            {userFriendlyAddress &&
                <PushWalletConnect push_text={"Wallet connected"}
                                   push_image={<svg xmlns="http://www.w3.org/2000/svg" width="19"
                                                    height="18" viewBox="0 0 19 18" fill="none">
                                       <g clipPath="url(#clip0_580_9669)">
                                           <path
                                               d="M16.7141 4.875H3.3125C3.00312 4.875 2.75 4.62188 2.75 4.3125C2.75 4.00312 3.00312 3.75 3.3125 3.75H16.8125C17.1219 3.75 17.375 3.49688 17.375 3.1875C17.375 2.25586 16.6191 1.5 15.6875 1.5H2.75C1.50898 1.5 0.5 2.50898 0.5 3.75V15C0.5 16.2445 1.50898 17.25 2.75 17.25H16.7141C17.6984 17.25 18.5 16.4941 18.5 15.5625V6.5625C18.5 5.63086 17.6984 4.875 16.7141 4.875ZM15.125 12.1875C14.5027 12.1875 14 11.6848 14 11.0625C14 10.4402 14.5027 9.9375 15.125 9.9375C15.7473 9.9375 16.25 10.4402 16.25 11.0625C16.25 11.6848 15.7473 12.1875 15.125 12.1875Z"
                                               fill="white"/>
                                       </g>
                                       <defs>
                                           <clipPath id="clip0_580_9669">
                                               <rect width="18" height="18" fill="white"
                                                     transform="translate(0.5)"/>
                                           </clipPath>
                                       </defs>
                                   </svg>
                                   }/>
            }
            <div className="user_data">
                <div className="user_data__header">
                    <div className="user_data__header__user_profile">
                        <div className='user_image'>
                            <p className='user_image__name'>{userName ? userName[0].toUpperCase() : 'U'}</p>
                        </div>
                        <p>{userName ? userName : 'Username'}</p>
                        {userID === 541456675 && <p>Самая красивая девушка на планете замеля ❤️</p>}
                    </div>

                    <TonConnectButton/>
                </div>
                <div className="user_data__items">
                    {props.plan_data ? <Protected props={props.plan_data}/> : <NotProteced/>}
                    <InitialCard/>
                </div>
            </div>

        </div>

    )
}

export {Homepage}


