import React from "react";
import DeGuardIcon from "../Icons/DeGuardIcon";

export const Welcomecard = () => {
  return (
    <>
      <div className="onboarding__main_content__logo">
        <DeGuardIcon />
        <h1>
          Welcome to <br /> DeGuard VPN
        </h1>
      </div>

      <p>
        Here you can earn points <br/> from our ecosystem
      </p>
    </>
  );
};
