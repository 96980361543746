import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../Icons/HomeIcon';
import VpnIcon from '../Icons/VpnIcon';
import FriendsIcon from '../Icons/FriendsIcon';
import TaskIcon from '../Icons/TaskIcon';

import '../Footer/Footer.css'

export const Footer = () => {
  
  return (
    <div className='footer'>            
      <NavLink to="/" className='footer_nav_bar_item'>
        <HomeIcon/>
        Home
      </NavLink>

      <NavLink to="/vpn" className='footer_nav_bar_item'>
        <VpnIcon/>
        VPN
      </NavLink>

      <NavLink to="/tasks" className='footer_nav_bar_item'>
        <TaskIcon/>
        Tasks
      </NavLink>

        <NavLink to="/friends" className='footer_nav_bar_item'>
            <FriendsIcon/>
            Friends
        </NavLink>


    </div>
  )
};
