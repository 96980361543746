import React from 'react'
import { Link } from 'react-router-dom'
import style from './NotProteced.module.css'
import ProtectLogoHome from '../Icons/svg/ProtectLogoHome'

export const NotProteced = () => {
  return (
    <div className={style.card}>
        <div className={style.card__content}>
            <div className={style.card__content__text}>
                <div className={style.protect_status}></div>
                <h1>You are not protected</h1>
            </div>
            <p>Buy plan with <span style={{color: "white"}}>20% discount</span></p>
            
        </div>
        <Link className={style.card__btn} to='/vpn'>Buy plan</Link>
    <ProtectLogoHome className={style.card__logo}/>
    </div>
  )
}
