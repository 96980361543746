import * as React from "react"
const TaskIcon = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 25 25"
  fill="none"
  {...props}
>
  <g className='active_svg' clipPath="url(#clip0_1_1780)">
    <path className='active_svg'
      d="M3.875 21.4455C3.875 22.2283 4.02902 22.7674 4.81191 23.1652C6.41618 23.9353 8.77768 24.5 11.9221 24.5C14.7584 24.5 17.8643 24.1021 20.2771 22.9599C21.5734 22.3438 21.8814 21.792 21.8814 20.4444V5.10749C21.8814 3.32353 21.0985 2.51497 19.3787 2.51497H18.4547V2.09145C18.4547 1.03904 17.9413 0.5 16.9659 0.5C16.6451 0.5 16.2472 0.564162 15.7852 0.731016C12.7948 1.71926 10.2151 2.4123 6.54453 2.4123H5.6718C4.54238 2.4123 3.875 3.07967 3.875 4.08074V21.4455ZM7.5841 10.2412V6.84011C7.5841 6.69893 7.68677 6.58341 7.82795 6.57059C10.2023 6.46792 12.2301 6.04437 14.3862 5.21016C14.5146 5.15882 14.7199 5.21015 14.7199 5.45402V9.04759C14.7199 9.13743 14.6686 9.22727 14.5787 9.26577C12.6921 10.0358 10.2793 10.485 7.82795 10.485C7.6996 10.485 7.5841 10.408 7.5841 10.2412ZM8.37981 22.6262C12.3456 22.5107 15.8237 21.2914 17.7488 19.8412C18.2237 19.4818 18.4547 19.0968 18.4547 18.1342V4.36311H19.3787C19.8279 4.36311 20.0333 4.59413 20.0333 5.12031V20.316C20.0333 20.9192 19.9563 21.1374 19.3787 21.3684C17.2996 22.2668 14.5659 22.8187 11.9221 22.8315C10.7798 22.8187 9.67606 22.7802 8.37981 22.6262Z"
      fill="white"
      fillOpacity={0.5}
    />
  </g>
  <defs>
    <clipPath id="clip0_1_1780">
      <rect
        width={24}
        height={24}
        fill="white"
        transform="translate(0.875 0.5)"
      />
    </clipPath>
  </defs>
</svg>
)
export default TaskIcon
