import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g className='active_svg' fill="#fff" fillOpacity={0.5} clipPath="url(#a)">
      <path d="M10.169 8.997c0 1.918-1.447 3.398-3.084 3.398C5.459 12.395 4 10.915 4 9.02 4 7.113 5.459 5.7 7.085 5.7c1.637 0 3.084 1.38 3.084 3.297ZM8.757 20.38H2.262c-1.2 0-1.637-.47-1.637-1.267 0-2.422 2.49-5.372 6.46-5.372 1.446 0 2.696.39 3.7 1.001-1.476 1.295-2.31 2.948-2.31 4.438 0 .42.082.833.282 1.2ZM17.2 12.193c1.885 0 3.545-1.693 3.545-3.902 0-2.198-1.66-3.791-3.544-3.791-1.873 0-3.533 1.637-3.533 3.813 0 2.187 1.66 3.88 3.533 3.88Zm-5.416 8.187h10.845c1.502 0 1.996-.426 1.996-1.2 0-2.276-2.849-5.416-7.424-5.416-4.565 0-7.413 3.14-7.413 5.416 0 .774.493 1.2 1.996 1.2Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.625.5h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
