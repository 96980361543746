import * as React from "react"
const HomeIcon = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path className='active_svg'
      fill="#fff"
      fillOpacity={0.5}
      d="M14.933 21.61H9.804v-6.425c0-.49.327-.792.805-.792h3.532c.49 0 .792.302.792.792v6.424Zm-11.327-.466c0 1.433.867 2.276 2.326 2.276H18.83c1.458 0 2.326-.843 2.326-2.276v-7.417L12.96 6.862a.84.84 0 0 0-1.157 0l-8.197 6.865v7.417Zm-3.231-8.7c0 .466.365.906.98.906.315 0 .579-.176.805-.365l9.894-8.31a.483.483 0 0 1 .654 0l9.894 8.31c.24.189.503.365.817.365.528 0 .956-.327.956-.868 0-.34-.1-.566-.34-.767L13.651 2.99c-.78-.653-1.748-.653-2.527 0L.727 11.715a.964.964 0 0 0-.352.73ZM18.441 7.68l2.716 2.288V5.392c0-.478-.315-.78-.78-.78h-1.131c-.478 0-.805.302-.805.78V7.68Z"
    />
  </svg>
)
export default HomeIcon
