import '../src/App.css';
import {Footer} from '../src/components/Footer/Footer';
import {Routes, Route, useLocation} from 'react-router-dom'
import {Homepage} from '../src/pages/Homepage';
import {Friendspage} from '../src/pages/Friendspage';
import {Taskspage} from '../src/pages/Taskspage';
import {Vpnpage} from '../src/pages/Vpnpage';
import Onboardingpage from './pages/Onboardingpage';
import {useEffect, useState} from 'react';
import {TonConnectUIProvider} from '@tonconnect/ui-react';
import Lottie from "lottie-react";
import processingAnimation from "./components/Lottie/processingAnimation.json";
import {useNotCoin} from "./Api/useNotcoin";

function App() {

    const TG_WEB_APP = window.Telegram.WebApp
    const [isOnboarding, setOnboarding] = useState(localStorage.getItem('onboarding'))
    const [userId, setUserId] = useState();
    const [userPlanData, setUserPlanData] = useState(localStorage.getItem('plan_data'));
    const [tgStartParam, setStartParam] = useState(window.Telegram.WebApp.initDataUnsafe?.start_param);
    const [telegramUserId, setTelegramUserId] = useState(null);
    const [tg_data, setTgtData] = useState();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        TG_WEB_APP.setHeaderColor('#000000');
        TG_WEB_APP.setBackgroundColor('#000000');
        // TG_WEB_APP.enableClosingConfirmation();
        TG_WEB_APP.ready();
        TG_WEB_APP.disableVerticalSwipes();
        TG_WEB_APP.expand();
        // localStorage.clear();
    }, [])

    if (!isOnboarding) {
        setTimeout(() => {
            const overflow = 100
            document.body.style.overflowY = 'hidden'
            document.body.style.marginTop = `${overflow}px`
            document.body.style.height = window.innerHeight + overflow + "px"
            document.body.style.paddingBottom = `${overflow}px`
            window.scrollTo(0, overflow)
        }, 500)
    } else {
        const overflow = 100
        document.body.style.overflowY = 'hidden'
        document.body.style.marginTop = `${overflow}px`
        document.body.style.height = window.innerHeight + overflow + "px"
        document.body.style.paddingBottom = `${overflow}px`
        window.scrollTo(0, overflow)
    }


    const changePlanDataHandler = (data) => {
        setUserPlanData(data)
        console.log(userPlanData)
    }

    const HEADERS = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    }

    const telegramData = {
        id: String(TG_WEB_APP.initDataUnsafe.user?.id),
        first_name: TG_WEB_APP.initDataUnsafe.user?.first_name,
        last_name: TG_WEB_APP.initDataUnsafe.user?.last_name,
        username: TG_WEB_APP.initDataUnsafe.user?.username,
        language_code: TG_WEB_APP.initDataUnsafe.user?.language_code


        // id: '785023632',
        // first_name: "Mikhail",
        // last_name: "Aleksin",
        // username: "mrxnzxt",
        // language_code: "ru"
    }



    const { fetchNotCoinTir } = useNotCoin();
    const [tierData, setTierData] = useState(null);

    const [isNotCoinLoadning, setIsNotCoinLoading] = useState();

    useEffect(() => {
        if (tgStartParam === 'notcoin') {
            setIsNotCoinLoading(true)
            const fetchData = async () => {
                const data = await fetchNotCoinTir(telegramData.id);
                setTierData(data);
            }
            fetchData().then(() => setIsNotCoinLoading(false));
            setPartnerId(1)
        }

    }, []);

    const [partnerId, setPartnerId] = useState();

    useEffect(() => {

        switch(tgStartParam) {
            case 'yumify':
                setPartnerId(2);
                break;
            case 'spheroid_universe':
                setPartnerId(4);
                break;
            case 'steamify':
                setPartnerId(5);
                break;
            case 'secret_pad':
                setPartnerId(6);
                break;
            case 'dragon':
                setPartnerId(7);
                break;
            case 'mole':
                setPartnerId(8);
                break;
            default:
                setPartnerId(null)
        }


        // if (tgStartParam === 'yumify') {
        //     setPartnerId(2)
        // }
    }, [tgStartParam])

    // useEffect(() => {
    //     if (partnerId) alert(partnerId)
    // }, [partnerId])
    //
    // console.log(partnerId)


    useEffect(() => {
        console.log(tierData)
    }, [tierData]);

    const getPlanData = (innerUserId) => fetch(`${apiUrl}/plans/${innerUserId}`, {
        method: 'GET',
        headers: HEADERS
    }).then(res => {
            if (res.status === 400) {
                setUserPlanData(false)
                localStorage.removeItem("plan_data")
            } else {
                res.json().then(res => {
                    setUserPlanData(res)
                    localStorage.setItem('plan_data', JSON.stringify(res))
                })
            }
        }
    ).catch(res => localStorage.removeItem("plan_data"))



    useEffect(() => {
        const fetchData = () => {
            fetch(`${apiUrl}/miniapp/get_user_id/${telegramData.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    }
                }
            ).then((res) => {
                res.json().then((res) => {
                    if (res !== false) {
                        console.log(res.user_id);
                        setUserId(res.user_id);
                        localStorage.setItem('user_id', res.user_id);
                        getPlanData(res.user_id);
                    } else {
                        fetch(`${apiUrl}/user/create/telegram_user`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                            },
                            body: JSON.stringify(telegramData)
                        }).then(res => res.json().then(res => {
                            if (res.user_data) {
                                setUserId(res.user_data.user_id);
                                getPlanData(res.user_data.user_id);
                                localStorage.setItem('user_id', res.user_data.user_id);
                            }
                        })).catch(res => console.log(res))
                    }
                })
            })
        }
        fetch(`${apiUrl}/servers/available_servers`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
            })
            .then(res => res.json()
                .then(res => {
                    localStorage.setItem("servers", JSON.stringify(res))
                })
                .catch(err => console.log(err)))

    fetchData();


    }, []);

    useEffect(() => {
        if (window.Telegram.WebApp.initDataUnsafe.start_param && !localStorage.getItem('isIncreaseBanner')) {
            fetch(`${apiUrl}/miniapp/increase_counter`, {
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify({"source": window.Telegram.WebApp.initDataUnsafe.start_param})
            })
                .then(res => res.json()
                    .then(res => {
                        localStorage.setItem('isIncreaseBanner', "true")
                    }))
                .catch(err => console.log(err))
        }
    }, [])


    const location = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);



    if (process.env.REACT_APP_STAND === 'DEV' && !(['2200829547', '785023632', '426000871', '6928492759', '1534268906'].find(el => el === telegramData.id))) {
        return <h1 style={{textAlign: 'center'}}>Sorry, you don't have access</h1>;
    }


    if (isNotCoinLoadning) {
        return <div className="centered-container">
            <Lottie
                className="awaiting_animation"
                animationData={processingAnimation}
            ></Lottie>
        </div>
    }

    return (
        <>
            {!userId ?
                <div className="centered-container">
                    <Lottie
                        className="awaiting_animation"
                        animationData={processingAnimation}
                    ></Lottie>
                </div> : <TonConnectUIProvider
                    manifestUrl="https://app.deguard.io/ton/tonconnect-manifest.json"
                    actionsConfiguration={{
                        twaReturnUrl: process.env.REACT_APP_TON_REDIRECT
                    }}
                >
                    <div className={`app page ${transitionStage}`}
                         onAnimationEnd={() => {
                             if (transitionStage === "fadeOut") {
                                 setTransistionStage("fadeIn");
                                 setDisplayLocation(location);
                             }
                         }}>
                        {!isOnboarding ? <Onboardingpage setOnboarding={setOnboarding}/>
                            : (
                                <>
                                    <Routes location={displayLocation}>
                                        <Route path='/' element={<Homepage plan_data={userPlanData}
                                                                           planHandler={changePlanDataHandler}
                                        />}/>
                                        <Route path='/vpn' element={<Vpnpage plan_data={userPlanData}
                                                                             planHandler={changePlanDataHandler}
                                                                             notcoin={tierData}
                                                                             partnerId={partnerId}
                                        />}/>
                                        <Route path='/tasks' element={<Taskspage/>}/>
                                        <Route path='/friends' element={<Friendspage/>}/>
                                    </Routes>

                                </>
                            )
                        }
                    </div>
                    {isOnboarding && <Footer/>}
                </TonConnectUIProvider>}
        </>
    )
}


export default App;

