export const useNotCoin = () => {
    const fetchNotCoinTir = async (telegramId) => {
        try {
            const response = await fetch(`https://clicker-api.joincommunity.xyz/tier/user/${telegramId}`);
            if (response.status === 200) {
                const data = await response.json();
                if (data["ok"]) {
                    return data.data;  // Возвращаем данные
                }
            }
        } catch (err) {
            console.error(err);
        }
        return null;  // Возвращаем null, если что-то пошло не так
    }

    return { fetchNotCoinTir }
}
