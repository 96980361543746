import React, {useEffect, useState} from 'react'
import {VpnCard} from '../components/VpnPlanCards/PassCard/VpnCard';
import {useIsConnectionRestored, useTonAddress} from '@tonconnect/ui-react';
import {PushWalletConnect} from '../components/Notification/PushWalletConnect';
import {VpnActive} from "../components/VpnActive/VpnActive";
import Lottie from "lottie-react";
import buyPlanAnimation from "../components/Lottie/buyPlanAnimation.json";
import styles from "../components/VpnActive/VpnActive.module.css";
import processingAnimation from "../components/Lottie/processingAnimation.json";
import {Partners} from "../components/Partners/Partners";
import {PlanData} from "../components/PlanData";


const Vpnpage = (props) => {
    const TG_WEB_APP = window.Telegram.WebApp

    const plan_data = {
        1: {
            "img_src": "/images/card_img_pass.png",
            "sale_amount": 30,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 7500,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/card_img_pass.png",
            "sale_amount": 20,
            "plan_name": "Annual plan",
            "plan_price_usd": 2500,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/card_img_pass.png",
            "sale_amount": 10,
            "plan_name": "Monthly plan",
            "plan_price_usd": 350,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 1 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }
    const plan_data_nomis = {
        1: {
            "img_src": "/images/nomis_plans_img/pass.png",
            "sale_amount": 20,
            "plan_name": "Unlimited NFT Pass",
            "plan_price_usd": 5000,
            "plan_id": 6,
            "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
        }, 2: {
            "img_src": "/images/nomis_plans_img/annual.png",
            "sale_amount": 30,
            "plan_name": "Annual plan",
            "plan_price_usd": 1500,
            "plan_id": 3,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],

            ]
        }, 3: {
            "img_src": "/images/nomis_plans_img/monthly.png",
            "sale_amount": 50,
            "plan_name": "Monthly plan",
            "plan_price_usd": 250,
            "plan_id": 1,
            "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 51devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
        },
    }


    const [notCoinPlanData, setNotCoinPlanData] = useState();

    useEffect(() => {
        const tirSale = {
            priceSale: {1: 0, 2: 0, 3: 0.15, 4: 0.40},
            procentSale: {1: 0, 2: 0, 3: 15, 4: 40},
            tirType: {1: "Bronze", 2: "Silver", 3: "Golden", 4: "Platinum"}
        }
        if (props.notcoin) setNotCoinPlanData({
            1: {
                "img_src": "/images/plans_icons/Notcoin_plans/NotcoinPass.png",
                "sale_amount": tirSale.procentSale[props.notcoin],
                "tir_type": tirSale.tirType[props.notcoin],
                "plan_name": "Unlimited NFT Pass",
                "plan_price_usd": Math.trunc(7500 - (7500 * tirSale.priceSale[props.notcoin])),
                "plan_id": 6,
                "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"], ["/images/plans_icons/dgp.svg", "Extra farming for Notcoin users", "+10% of plan points"]]
            }, 2: {
                "img_src": "/images/plans_icons/Notcoin_plans/NotcoinAnnual.png",
                "sale_amount": tirSale.procentSale[props.notcoin],
                "tir_type": tirSale.tirType[props.notcoin],
                "plan_name": "Annual plan",
                "plan_price_usd": Math.trunc(2500 - (2500 * tirSale.priceSale[props.notcoin])),
                "plan_id": 3,
                "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"], ["/images/plans_icons/dgp.svg", "Extra farming for Notcoin users", "+10% of plan points"]

                ]
            }, 3: {
                "img_src": "/images/plans_icons/Notcoin_plans/NotcoinMonthly.png",
                "sale_amount": tirSale.procentSale[props.notcoin],
                "tir_type": tirSale.tirType[props.notcoin],
                "plan_name": "Monthly plan",
                "plan_price_usd": Math.trunc(350 - (350 * tirSale.priceSale[props.notcoin])),
                "plan_id": 1,
                "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 1 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"], ["/images/plans_icons/dgp.svg", "Extra farming for Notcoin users", "+10% of plan points"]]
            },
        })
    }, [props.notcoin])

    // STATES
    const [index, setIndex] = useState(1);
    const [isWalletCon, setWalletCon] = useState(false);
    const [paymentStatus, setPaymnetStatus] = useState();
    const [isPlanBuy, setIsPlanBuy] = useState();
    const [userPlanData, setUserPlanData] = useState(JSON.parse(localStorage.getItem('plan_data')));
    const [invoiceLink, setInvoiceLink] = useState();
    const [isNomisHolder, setNomisHolder] = useState();
    const [isBuyBtnActive, setBuyBtnActive] = useState();
    const [partnerId, setPartnerId] = useState();
    const userFriendlyAddress = useTonAddress();
    const connectionRestored = useIsConnectionRestored();
    const apiUrl = process.env.REACT_APP_API_URL;


    const [requestSent, setRequestSent] = useState(false); // Новое состояние для отслеживания отправки запроса

    useEffect(() => {
        const invoiceHandler = (e) => {
            setPaymnetStatus(e?.status);
            console.log(e?.status)

            if (e.status === 'paid' && !requestSent) {
                const body = {
                    user_id: localStorage.getItem('user_id'),
                    user_plan: Number(localStorage.getItem('tmp_plan_id')),
                    from_tg_bot: true,
                }

                if (props.partnerId) {
                    body.from_partner = props.partnerId;
                }
                const ss = sessionStorage.getItem('partner_id')
                if (ss) {
                    body.from_partner = Number(ss);
                }
                const createPlan = () => fetch(`${apiUrl}/plans/create`, {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    }, body: JSON.stringify(body)
                }).then(res => {
                    if (res.status === 400) {
                        console.log(res)
                        setBuyBtnActive(false)
                        sessionStorage.removeItem('partner_id')
                    } else {
                        res.json().then(res => {
                            setIsPlanBuy(true)
                            props.planHandler(res)
                            setUserPlanData(res)
                            localStorage.setItem('plan_data', JSON.stringify(res))
                            setBuyBtnActive(false)
                            sessionStorage.removeItem('partner_id')
                        })
                    }
                }).catch(res => {
                    setBuyBtnActive(false)
                    sessionStorage.removeItem('partner_id')
                })

                createPlan();
                setRequestSent(true);
            } else {
                localStorage.removeItem('tmp_plan_id')
                sessionStorage.removeItem('partner_id')
                setBuyBtnActive(false)

            }
        }

        window.Telegram.WebApp.onEvent('invoiceClosed', invoiceHandler);
    }, []);

    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        console.log(userFriendlyAddress)
        if (userFriendlyAddress) {
            setIsLoading(true)
            fetch(`https://nomis.cc/api/ton/holder?address=${userFriendlyAddress}`)
                .then(res => res.json().then(res => {
                    console.log(res.isHolder)
                    if (res.isHolder) {
                        sessionStorage.setItem('partner_id', "3")
                        setIsLoading(false)
                        setNomisHolder(true)
                    }
                    setIsLoading(false)
                }))
                .catch(err => setIsLoading(false))
        }


    }, [userFriendlyAddress])

    useEffect(() => {
        setTimeout(() => {
            setIsPlanBuy(false)
        }, 1000);
    }, [isPlanBuy]);

    const paymentHandler = () => {
        setBuyBtnActive(true)
        const getInvoice = () => fetch(`${apiUrl}/miniapp/create_invoice`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }, body: JSON.stringify({
                plan_id: plan_data[index].plan_id,
                user_id: localStorage.getItem('user_id'),
                lang_code: TG_WEB_APP.initDataUnsafe.user?.language_code || 'ru'
            })
        }).then(res => res.json().then((res) => {
            if (res?.invoice_link) {
                localStorage.setItem("tmp_plan_id", plan_data[index].plan_id)
                TG_WEB_APP.openInvoice(res.invoice_link);
                setInvoiceLink(res.invoice_link);
            }
        })).catch(res => {
            setBuyBtnActive(false)
            console.log(res)
        });

        getInvoice();
    }

    const paymentHandlerNomis = () => {
        setBuyBtnActive(true)
        const getInvoice = () => fetch(`${apiUrl}/miniapp/create_invoice`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }, body: JSON.stringify({
                plan_id: plan_data[index].plan_id,
                user_id: localStorage.getItem('user_id'),
                lang_code: TG_WEB_APP.initDataUnsafe.user?.language_code || 'ru',
                from_nomis: true,
                not_coin_tir: props.notcoin

            })
        }).then(res => res.json().then((res) => {
            if (res?.invoice_link) {
                localStorage.setItem("tmp_plan_id", plan_data[index].plan_id)
                TG_WEB_APP.openInvoice(res.invoice_link);
                setInvoiceLink(res.invoice_link);
            }
        })).catch(res => setBuyBtnActive(false));

        getInvoice();
    }

    const paymentHandlerNotCoin = () => {
        setBuyBtnActive(true)
        const getInvoice = () => fetch(`${apiUrl}/miniapp/create_invoice`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }, body: JSON.stringify({
                plan_id: plan_data[index].plan_id,
                user_id: localStorage.getItem('user_id'),
                lang_code: TG_WEB_APP.initDataUnsafe.user?.language_code || 'ru',
                from_not_coin: true,
                not_coin_tir: props.notcoin

            })
        }).then(res => res.json().then((res) => {
            if (res?.invoice_link) {
                localStorage.setItem("tmp_plan_id", plan_data[index].plan_id)
                TG_WEB_APP.openInvoice(res.invoice_link);
                setInvoiceLink(res.invoice_link);

            }
        })).catch(res => {
            setBuyBtnActive(false)
            console.log(res)
        });

        getInvoice();
    }

    const paymentHandlerYesCoin = () => {
        setBuyBtnActive(true)
        const getInvoice = () => fetch(`${apiUrl}/miniapp/create_invoice`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }, body: JSON.stringify({
                plan_id: plan_data[index].plan_id,
                user_id: localStorage.getItem('user_id'),
                lang_code: TG_WEB_APP.initDataUnsafe.user?.language_code || 'ru',
                from_partner: props?.partnerId
            })
        }).then(res => res.json().then((res) => {
            if (res?.invoice_link) {
                localStorage.setItem("tmp_plan_id", plan_data[index].plan_id)
                TG_WEB_APP.openInvoice(res.invoice_link);
                setInvoiceLink(res.invoice_link);
                // setBuyBtnActive(false)
            }
        })).catch(res => console.log(res));

        getInvoice();
    }

    const hapticFeedBack = () => {
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
    };

    const changeIndexHandler = (index) => {
        setIndex(index)
    }

    const buttonHandler = (state) => {
        setBuyBtnActive(state)
    }
    if (isLoading) {
        return <Lottie
            className="awaiting_animation"
            animationData={processingAnimation}
        ></Lottie>
    }

    console.log(props.partnerId)
    return (<div className='vpn'>

            {userPlanData ? (<>
                    {paymentStatus === 'paid' && <PushWalletConnect push_text={"Plan Purchased"}/>}
                    {isPlanBuy && <div className="centered-container_vpnpage">
                        <Lottie
                            className="awaiting_animation_vpnpage"
                            animationData={buyPlanAnimation}
                        ></Lottie>
                    </div>}
                    <VpnActive props={userPlanData}/>
                </>

            ) : (isNomisHolder ? (// NOMIS
                <div className="vpn__heading">
                    <div className="vpn__heading__main">
                        <div className="vpn__button_slider">
                            <button
                                className={index === 1 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                onClick={() => {
                                    setIndex(1)
                                }}>Forever
                            </button>
                            <button
                                className={index === 2 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                onClick={() => {
                                    setIndex(2)
                                }}>Annual
                            </button>
                            <button
                                className={index === 3 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                onClick={() => {
                                    setIndex(3)
                                }}>Monthly
                            </button>
                        </div>
                        <VpnCard props={plan_data_nomis[index]} nomis="true"/>
                    </div>

                    <button disabled={isBuyBtnActive} className="vpn__button_buy_plan_btn" onClick={() => {
                        paymentHandlerNomis()
                        hapticFeedBack()
                    }}>
                        {isBuyBtnActive ? (<div className={styles.loader}>
                            <div className={styles.inner + " " + styles.one}></div>
                            <div className={styles.inner + " " + styles.two}></div>
                            <div className={styles.inner + " " + styles.three}></div>
                        </div>) : (<>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82867 19.7716C4.24899 20.0897 4.76021 19.9647 5.37366 19.5217L9.99729 16.1249L14.6323 19.5217C15.2344 19.9647 15.7456 20.0897 16.1659 19.7716C16.5749 19.4649 16.6544 18.9423 16.4045 18.238L14.5755 12.7964L19.2446 9.4451C19.858 9.02476 20.1193 8.559 19.9489 8.05913C19.7899 7.582 19.3127 7.34345 18.563 7.3548L12.8374 7.40024L11.0993 1.92457C10.872 1.20889 10.5199 0.833984 9.99729 0.833984C9.48609 0.833984 9.13392 1.20889 8.89535 1.92457L7.15723 7.40024L1.43163 7.3548C0.681867 7.34345 0.216091 7.582 0.0456871 8.05913C-0.113356 8.559 0.147931 9.02476 0.750036 9.4451L5.4191 12.7964L3.5901 18.238C3.34018 18.9423 3.4197 19.4649 3.82867 19.7716Z"
                                    fill="#0A0C0D"/>
                            </svg>
                            <p style={{
                                color: "black",
                                fontSize: "16px",
                                fontFamily: "VK Sans Display",
                                fontWeight: "500",
                                lineHeight: "140%"
                            }}>Buy with Stars</p>
                        </>)}
                    </button>
                </div>) : props.notcoin && notCoinPlanData ? (<div className="vpn__heading">
                    <div className="vpn__heading__main">
                        <div className="vpn__button_slider">
                            <button
                                className={index === 1 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                onClick={() => {
                                    setIndex(1)
                                }}>Forever
                            </button>
                            <button
                                className={index === 2 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                onClick={() => {
                                    setIndex(2)
                                }}>Annual
                            </button>
                            <button
                                className={index === 3 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                onClick={() => {
                                    setIndex(3)
                                }}>Monthly
                            </button>
                        </div>
                        <VpnCard props={notCoinPlanData[index]} nomis={false} notCoin={true}/>
                    </div>
                    <button disabled={isBuyBtnActive} className="vpn__button_buy_plan_btn" onClick={() => {
                        paymentHandlerNotCoin()
                        hapticFeedBack()
                    }}>
                        {isBuyBtnActive ? (<div className={styles.loader}>
                            <div className={styles.inner + " " + styles.one}></div>
                            <div className={styles.inner + " " + styles.two}></div>
                            <div className={styles.inner + " " + styles.three}></div>
                        </div>) : (<>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.82867 19.7716C4.24899 20.0897 4.76021 19.9647 5.37366 19.5217L9.99729 16.1249L14.6323 19.5217C15.2344 19.9647 15.7456 20.0897 16.1659 19.7716C16.5749 19.4649 16.6544 18.9423 16.4045 18.238L14.5755 12.7964L19.2446 9.4451C19.858 9.02476 20.1193 8.559 19.9489 8.05913C19.7899 7.582 19.3127 7.34345 18.563 7.3548L12.8374 7.40024L11.0993 1.92457C10.872 1.20889 10.5199 0.833984 9.99729 0.833984C9.48609 0.833984 9.13392 1.20889 8.89535 1.92457L7.15723 7.40024L1.43163 7.3548C0.681867 7.34345 0.216091 7.582 0.0456871 8.05913C-0.113356 8.559 0.147931 9.02476 0.750036 9.4451L5.4191 12.7964L3.5901 18.238C3.34018 18.9423 3.4197 19.4649 3.82867 19.7716Z"
                                        fill="#0A0C0D"/>
                                </svg>
                                <p style={{
                                    color: "black",
                                    fontSize: "16px",
                                    fontFamily: "VK Sans Display",
                                    fontWeight: "500",
                                    lineHeight: "140%"
                                }}>Buy with Stars</p>
                            </>)}
                    </button>
                    {/*<button className="vpn__button_buy_plan_btn" onClick={paymentHandlerNotCoin}>*/}
                    {/*    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                    {/*         xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path*/}
                    {/*            d="M3.82867 19.7716C4.24899 20.0897 4.76021 19.9647 5.37366 19.5217L9.99729 16.1249L14.6323 19.5217C15.2344 19.9647 15.7456 20.0897 16.1659 19.7716C16.5749 19.4649 16.6544 18.9423 16.4045 18.238L14.5755 12.7964L19.2446 9.4451C19.858 9.02476 20.1193 8.559 19.9489 8.05913C19.7899 7.582 19.3127 7.34345 18.563 7.3548L12.8374 7.40024L11.0993 1.92457C10.872 1.20889 10.5199 0.833984 9.99729 0.833984C9.48609 0.833984 9.13392 1.20889 8.89535 1.92457L7.15723 7.40024L1.43163 7.3548C0.681867 7.34345 0.216091 7.582 0.0456871 8.05913C-0.113356 8.559 0.147931 9.02476 0.750036 9.4451L5.4191 12.7964L3.5901 18.238C3.34018 18.9423 3.4197 19.4649 3.82867 19.7716Z"*/}
                    {/*            fill="#0A0C0D"/>*/}
                    {/*    </svg>*/}
                    {/*    <p style={{*/}
                    {/*        color: "black",*/}
                    {/*        fontSize: "16px",*/}
                    {/*        fontFamily: "VK Sans Display",*/}
                    {/*        fontWeight: "500",*/}
                    {/*        lineHeight: "140%"*/}
                    {/*    }}>Buy with Stars</p>*/}
                    {/*</button>*/}
                </div>)

                : props.partnerId ? // YESCOIN
                    (
                        <Partners
                            index={index}
                            isBuyBtnActive={isBuyBtnActive}
                            changeIndexHandler={changeIndexHandler}
                            buttonHandler={buttonHandler}
                            plan_data={PlanData(props.partnerId)}
                            partnerId={props.partnerId}
                        />
                        // <div className="vpn__heading">
                        //     <div className="vpn__heading__main">
                        //         <div className="vpn__button_slider">
                        //             <button
                        //                 className={index === 1 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                        //                 onClick={() => {
                        //                     setIndex(1)
                        //                 }}>Forever
                        //             </button>
                        //             <button
                        //                 className={index === 2 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                        //                 onClick={() => {
                        //                     setIndex(2)
                        //                 }}>Annual
                        //             </button>
                        //             <button
                        //                 className={index === 3 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                        //                 onClick={() => {
                        //                     setIndex(3)
                        //                 }}>Monthly
                        //             </button>
                        //         </div>
                        //         <VpnCard props={plan_data_yescoin[index]} nomis={false} notCoin={false}
                        //                  partnerId={props.partnerId}/>
                        //     </div>
                        //     <button disabled={isBuyBtnActive} className="vpn__button_buy_plan_btn" onClick={() => {
                        //         paymentHandlerYesCoin()
                        //         hapticFeedBack()
                        //     }}>
                        //         {isBuyBtnActive ? (<div className={styles.loader}>
                        //             <div className={styles.inner + " " + styles.one}></div>
                        //             <div className={styles.inner + " " + styles.two}></div>
                        //             <div className={styles.inner + " " + styles.three}></div>
                        //         </div>) : (<>
                        //             <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                        //                  xmlns="http://www.w3.org/2000/svg">
                        //                 <path
                        //                     d="M3.82867 19.7716C4.24899 20.0897 4.76021 19.9647 5.37366 19.5217L9.99729 16.1249L14.6323 19.5217C15.2344 19.9647 15.7456 20.0897 16.1659 19.7716C16.5749 19.4649 16.6544 18.9423 16.4045 18.238L14.5755 12.7964L19.2446 9.4451C19.858 9.02476 20.1193 8.559 19.9489 8.05913C19.7899 7.582 19.3127 7.34345 18.563 7.3548L12.8374 7.40024L11.0993 1.92457C10.872 1.20889 10.5199 0.833984 9.99729 0.833984C9.48609 0.833984 9.13392 1.20889 8.89535 1.92457L7.15723 7.40024L1.43163 7.3548C0.681867 7.34345 0.216091 7.582 0.0456871 8.05913C-0.113356 8.559 0.147931 9.02476 0.750036 9.4451L5.4191 12.7964L3.5901 18.238C3.34018 18.9423 3.4197 19.4649 3.82867 19.7716Z"
                        //                     fill="#0A0C0D"/>
                        //             </svg>
                        //             <p style={{
                        //                 color: "black",
                        //                 fontSize: "16px",
                        //                 fontFamily: "VK Sans Display",
                        //                 fontWeight: "500",
                        //                 lineHeight: "140%"
                        //             }}>Buy with Stars</p>
                        //         </>)}
                        //     </button>
                        //
                        // </div>


                    ) : (<div className="vpn__heading">
                        <div className="vpn__heading__main">
                            <div className="vpn__button_slider">
                                <button
                                    className={index === 1 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                    onClick={() => {
                                        setIndex(1)
                                    }}>Forever
                                </button>
                                <button
                                    className={index === 2 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                    onClick={() => {
                                        setIndex(2)
                                    }}>Annual
                                </button>
                                <button
                                    className={index === 3 ? "vpn__button_slider_item btn_active" : "vpn__button_slider_item"}
                                    onClick={() => {
                                        setIndex(3)
                                    }}>Monthly
                                </button>
                            </div>
                            <VpnCard props={plan_data[index]}/>
                        </div>

                        <button disabled={isBuyBtnActive} className="vpn__button_buy_plan_btn" onClick={() => {
                            paymentHandler()
                            hapticFeedBack()
                        }}>
                            {isBuyBtnActive ? (<div className={styles.loader}>
                                <div className={styles.inner + " " + styles.one}></div>
                                <div className={styles.inner + " " + styles.two}></div>
                                <div className={styles.inner + " " + styles.three}></div>
                            </div>) : (<>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.82867 19.7716C4.24899 20.0897 4.76021 19.9647 5.37366 19.5217L9.99729 16.1249L14.6323 19.5217C15.2344 19.9647 15.7456 20.0897 16.1659 19.7716C16.5749 19.4649 16.6544 18.9423 16.4045 18.238L14.5755 12.7964L19.2446 9.4451C19.858 9.02476 20.1193 8.559 19.9489 8.05913C19.7899 7.582 19.3127 7.34345 18.563 7.3548L12.8374 7.40024L11.0993 1.92457C10.872 1.20889 10.5199 0.833984 9.99729 0.833984C9.48609 0.833984 9.13392 1.20889 8.89535 1.92457L7.15723 7.40024L1.43163 7.3548C0.681867 7.34345 0.216091 7.582 0.0456871 8.05913C-0.113356 8.559 0.147931 9.02476 0.750036 9.4451L5.4191 12.7964L3.5901 18.238C3.34018 18.9423 3.4197 19.4649 3.82867 19.7716Z"
                                            fill="#0A0C0D"/>
                                    </svg>
                                    <p style={{
                                        color: "black",
                                        fontSize: "16px",
                                        fontFamily: "VK Sans Display",
                                        fontWeight: "500",
                                        lineHeight: "140%"
                                    }}>Buy with Stars</p>
                                </>)}
                        </button>
                    </div>))}
        </div>)

}

export {Vpnpage}